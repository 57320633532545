import * as React from "react"
import * as styles from "src/scss/_newsNotification.module.scss"

const NewsNotification = () => {
  return (
    <div className={styles.notificationWrapper}>
      <div>
        <p className={styles.newsTopic}>NEWS</p>
      </div>
      <div>
        <a href="/news" className={styles.newsLink}>長岡事業所移転のお知らせ</a>
      </div>
    </div>
  )
}

export default NewsNotification
